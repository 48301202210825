h1,
.h1 {
    @apply text-[32px] font-bold;
}

p,
.p {
    @apply text-base font-normal;
}

a,
.a {
    @apply text-[#333333] no-underline;
}

a:hover,
.a:hover {
    @apply text-black underline;
}